import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import Img from 'gatsby-image';
import Layout from '../../components/layout';
import richTextParser from '../../utils/rich-text-parser';
import { LanguageContext } from '../../context';

import styles from './agency.module.css';

function PortraitFondateurs(props) {
  const { portraitFondateurs } = props;
  return (
    <div className={styles.portraitWrapper}>
      {portraitFondateurs.map((img) => {
        return (
          <div className={styles.portraitContainer} key={`multiImg-${img.id}`}>
            <Img className={styles.portrait} alt={`multiImg-${img.id}`} fluid={img.fluid} />
          </div>
        );
      })}
    </div>
  );
}
PortraitFondateurs.propTypes = {
  portraitFondateurs: PropTypes.array.isRequired,
};

function Description(props) {
  const { description } = props;
  return <div>{richTextParser(description.json)}</div>;
}
Description.propTypes = {
  description: PropTypes.object.isRequired,
};

function PhotosAgence(props) {
  const { photosAgence } = props;
  return photosAgence.map((img) => {
    return (
      <div key={`multiImg-${img.id}`}>
        <Img className={styles.images} alt={`multiImg-${img.id}`} fluid={img.fluid} />
      </div>
    );
  });
}
PhotosAgence.propTypes = {
  photosAgence: PropTypes.array.isRequired,
};

function AgencyTemplate(props) {
  const agency = get(props, 'data.contentfulAgency');
  const location = get(props, 'location');
  return (
    <Layout location={location}>
      <LanguageContext.Consumer>
        {(localeFromReactContext) => {
          return (
            <>
              <Helmet title={localeFromReactContext === 'fr' ? "L'agence" : 'Agency'} />
              <div className={styles.wrapper}>
                <PortraitFondateurs portraitFondateurs={agency.portraitFondateurs} />
                <Description description={agency.description} />
                <PhotosAgence photosAgence={agency.photosAgence} />
              </div>
            </>
          );
        }}
      </LanguageContext.Consumer>
    </Layout>
  );
}

export default AgencyTemplate;

export const pageQuery = graphql`
  query Agency($locale: String!) {
    contentfulAgency(node_locale: { eq: $locale }) {
      portraitFondateurs {
        id
        description
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      description {
        json
      }
      photosAgence {
        id
        description
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;
